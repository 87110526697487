import ApiService from "@/core/services/ApiService";
import { processErrors } from "./errorsProcessing";

const url = "api/v1/statementSettings";

export async function getStatementSettings() {
  return await ApiService.get(url)
    .then((res) => {
      return res.data;
    })
    .catch(({ response }) => {
      processErrors(response);
    });
}

export async function updateStatementSettings(settings) {
  if (settings) {
    const headers = { "Content-Type": "multipart/form-data" };
    await ApiService.postForm(url, settings, { headers: headers })
      .then()
      .catch(({ response }) => {
        processErrors(response);
      });
  }
}

